import React from 'react';
import logo from './logo.svg';
import './Alpha.css';

function Alpha() {
  return (
    <div className="Alpha">
      <header className="Alpha-header">
        <img src={logo} className="Alpha-logo" alt="logo" />
        <p>
          Alpha Limo
        </p>
        <a
          className="Alpha-link"
          href="mailto:info@alpha.limo"
          target="_blank"
          rel="noopener noreferrer"
        >
        &copy; 2025 Alpha Limo LLC
        </a>
      </header>
    </div>
  );
}

export default Alpha;